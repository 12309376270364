<template>
  <div class="index-container">
    <div class="head">
      <yicangHead class="yicang-head"></yicangHead>
      <headNav currentPage="加入义仓" class="head-nav"></headNav>
    </div>

    <div class="body">
      <step-page :textList="dataList" title="加入义仓"></step-page>
    </div>

    <div class="footer">
      <footerBox></footerBox>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import { API } from "@/const/const";

// head
import yicangHead from "../components/header/head";
import headNav from "../components/header/headNav";
// main
import StepPage from "../components/public/StepPage";
// footer
import footerBox from "../components/footers/index";

export default {
  name: "Join",
  components: {
    yicangHead,
    headNav,
    StepPage,
    footerBox
  },
  data() {
    return {
      dataList: []
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.getInfoList();
    });
  },
  methods: {
    getInfoList() {
      if (sessionStorage.getItem("JOIN_US")) {
        this.dataList = JSON.parse(sessionStorage.getItem("JOIN_US"));
      } else {
        request.get(API.JOIN_US).then(res => {
          this.dataList = res.join_us;
          sessionStorage.setItem("JOIN_US", JSON.stringify(res.join_us));
        });
      }
    }
  }
};
</script>
